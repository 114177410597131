/*
 * Content:
 *
 * Root
 */


// Root
// ============================================================================

:root {
  // Colors
  @each $color, $value in $grays-rgb {
    --#{$prefix}gray-#{$color}-rgb: #{$value}; // stylelint-disable-line custom-property-pattern
  }

  @each $color, $value in $colors-rgb {
    --#{$prefix}#{$color}-rgb: #{$value};
  }

  @each $color, $value in $theme-colors-light-rgb {
    --#{$prefix}#{$color}-light-rgb: #{$value};
  }

  --#{$prefix}subtitle-color: #{$text-subtitle};

  // Shadows
  --#{$prefix}box-shadow-light: #{$box-shadow-light};
  --#{$prefix}box-drop-shadow: #{$box-drop-shadow};
  --#{$prefix}box-drop-shadow-light: #{$box-drop-shadow-light};
  --#{$prefix}box-shadow-switch: #{$box-shadow-switch};
}
