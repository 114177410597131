/*
 * Content:
 *
 * Progress bar
 * Progress bar item
 * Progress stacked
 */

.progress-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: spacer(1);
  width: 100%;
}

.progress-bar-item {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: spacer(1);
  font-size: $font-size-sm;

  .bar {
    width: 100%;
    height: spacer(1);
    background: $gray-400;

    &[data-active-item="True"] {
      background: $secondary;
    }
  }

  .step {
    display: none;
    font-weight: $font-weight-bold;
    color: $gray-400;
    text-wrap: wrap;

    &[data-active-item="True"] {
      color: $secondary;
    }

    &::before {
      content: attr(data-step) "- ";
    }
  }

  @include media-breakpoint-up(lg) {
    .step {
      display: block;
    }
  }
}

// Progress stacked
// ============================================================================

.progress-stacked {
  > .progress {
    height: 100%;
  }
}
