/*
 * Content:
 *
 * Blog content
 * Video player
 * Blog list
 * Post list
 * Communications related news
 */

// Blog content
// ============================================================================
.blog-content {
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}


// Blog list
// ============================================================================
.blog-list {
  margin-bottom: spacer(5);

  .pagination {
    display: flex;
    align-items: center;
    gap: spacer(3);

    .nav-btn {
      $nav-button-size: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: $nav-button-size;
      height: $nav-button-size;

      font-size: $h5-font-size;
      border: $btn-border-width solid $gray-200;
      border-radius: 50%;

      &[data-is-current="true"] {
        color: $white;
        background-color: $secondary;
        border: transparent;
      }
    }

    a {
      color: $body;
      text-decoration: none;
    }

    .current {
      padding: spacer(1);
    }
  }
}


// Video player
// ============================================================================
.djangocms-video-plugin {
  $video-iframe-height: 650px;

  iframe {
    width: 100%;
    height: $video-iframe-height;
  }
}


// Post item
// ============================================================================
.post-item {
  $image-height: 185px;

  img {
    width: 100%;
    height: $image-height;
  }
}

// Blog item secondary
// ============================================================================
.blog-item-secondary-img {
  width: 100%;
  min-height: 10.5rem;
  background-position: center;
  background-size: cover;
  aspect-ratio: unset;

  @include media-breakpoint-up(md) {
    width: rem(172px);
    aspect-ratio: 1;
    margin-bottom: 0;
  }
}
