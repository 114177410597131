/*
 * Content:
 *
 * News grid section
 */


// News grid section
// ============================================================================

.news-grid-section {
  display: grid;
  gap: spacer(5);
  grid-template:
    "post4"
    "post2"
    "post3"
    "post1"
    "post5"
    "post6";

  @include media-breakpoint-up(md) {
    grid-template:
      "post1 post4 post4" 1fr
      "post2 post4 post4" 1fr
      "post3 post5 post6" 1fr / 83fr 50fr 50fr;
  }
}
