/*
 * Content:
 *
 * Loader overlay
 */


// Loader overlay
// ============================================================================
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(2px) brightness(90%);

  .loader {
    animation: spin 3s linear infinite;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
