/*
 * Content:
 *
 * Modules
 * Notifications popup button
 * Notification date
 * Notification content
 */

// Modules
// ============================================================================

@use "sass:map";


// Notifications popup button
// ============================================================================

.notifications-popup-button {
  position: relative;

  &.unread {
    &::before {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: rem(8px);
      height: rem(8px);
      content: "";
      background-color: $red;
      border-radius: 100%;
    }
  }
}


// Notification date
// ============================================================================

.notification-date {
  display: block;
  margin-bottom: spacer(2);
  font-size: $font-size-xs;
  text-align: end;
  margin-inline-start: auto;
}


// Notification content
// ============================================================================

.notification-content {
  display: flex;
  gap: spacer(3);
  margin-bottom: 0;
  font-size: $font-size-lg;

  &.unread {
    &::before {
      display: inline-block;
      flex-shrink: 0;
      width: rem(14px);
      height: rem(14px);
      margin-top: rem(8px);
      content: "";
      background-color: $red;
      border-radius: 100%;
    }
  }
}
