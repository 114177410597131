/*
 * Content:
 *
 * Glider js default styling and transitions for carousel
 * Glider posts
 */


// Glider js default styling and transitions for carousel
// ============================================================================

.glider-contain {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.glider {
  position: relative;
  margin: 0 auto;
  overflow-y: hidden;
  transform: translateZ(0);
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  // Hide slides that should not be visible before initialization
  &:not(.loaded) {
    .glider-track > :first-child ~ * {
      display: none !important; // stylelint-disable-line declaration-no-important
    }

    &[data-glider-slides-to-show-md="3"] {
      @include media-breakpoint-up(md) {
        .glider-track > :first-child ~ * {
          display: inherit !important; // stylelint-disable-line declaration-no-important
        }

        .glider-track > :nth-of-type(3) ~ * {
          display: none !important; // stylelint-disable-line declaration-no-important
        }
      }
    }
  }
}

.glider-track {
  z-index: 1;
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
  transform: translateZ(0);
}

.glider.draggable {
  cursor: grab;
  user-select: none;
}

.glider.draggable .glider-slide img {
  pointer-events: none;
  user-select: none;
}

.glider.drag {
  cursor: grabbing;
}

.glider-slide {
  align-content: center;
  justify-content: center;
  width: 100%;
  user-select: none;
}

.glider-prev,
.glider-next {
  position: absolute;
  top: 30%;
  left: -23px;
  z-index: 2;
  padding: 0;
  font-size: 40px;
  line-height: 1;
  color: #666;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  background: none;
  border: 0;
  outline: none;
  opacity: 1;
  transition:
    opacity .5s cubic-bezier(.17, .67, .83, .67),
    color .5s cubic-bezier(.17, .67, .83, .67);
}

.glider-prev:hover,
.glider-next:hover,
.glider-prev:focus,
.glider-next:focus {
  color: #a89cc8;
}

.glider-next {
  right: -23px;
  left: auto;
}

.glider-next.disabled,
.glider-prev.disabled {
  color: #666;
  cursor: default;
  opacity: .25;
}

.glider-hide {
  opacity: 0;
}

.glider-dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 0 auto;
  margin-top: $glider-dot-group-margin-top;
  user-select: none;
  gap: spacer(2);
}

.glider-dot {
  display: block;
  width: $glider-dot-width;
  height: $glider-dot-width;
  padding: 0;
  cursor: pointer;
  user-select: none;
  background: $glider-dot-bg;
  border: 0;
  border-radius: 50%;
  outline: none;

  &:hover,
  &:focus {
    background: $glider-dot-hover-bg;
  }

  &.active {
    background: $glider-dot-active-bg;
  }
}


// Glider posts
// ============================================================================

.glider-posts {
  margin-inline: $grid-gutter-width * .5 * -1;
  padding-bottom: $glider-posts-shadow-y;

  .glider-slide {
    margin-inline: $grid-gutter-width * .5;
  }

  ~ .glider-dots {
    position: relative;
    z-index: 1;
    margin-top: calc(#{$glider-dot-group-margin-top} - #{$glider-posts-shadow-y});
  }

  @include media-breakpoint-up(md) {
    .glider-slide {
      margin-inline: $glider-posts-gap * .5;

      &:nth-child(3n) {
        margin-right: $grid-gutter-width * .5;
      }

      &:nth-child(3n - 2) {
        margin-left: $grid-gutter-width * .5;
      }
    }

    &:has(.glider-slide.visible.left-1:not(:nth-child(3n - 2))) {
      .glider-slide {
        &.left-1 {
          margin-right: $glider-posts-gap * .5;
          margin-left: $grid-gutter-width * .5;
        }

        &.center {
          margin-inline: $glider-posts-gap * .5;
        }

        &.right-1 {
          margin-right: $grid-gutter-width * .5;
          margin-left: $glider-posts-gap * .5;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin-inline: $glider-posts-shadow-x * -1;
    overflow-x: hidden;

    .glider-slide {
      &:nth-child(3n) {
        margin-right: $glider-posts-shadow-x;
      }

      &:nth-child(3n - 2) {
        margin-left: $glider-posts-shadow-x;
      }
    }

    &:has(.glider-slide.visible.left-1:not(:nth-child(3n - 2))) {
      .glider-slide {
        &.left-1 {
          margin-left: $glider-posts-shadow-x;
        }

        &.right-1 {
          margin-right: $glider-posts-shadow-x;
        }
      }
    }
  }
}
