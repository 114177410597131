/*
 * Content:
 *
 * Modules
 * Bootstrap mixins
 * Backgrounds
 * Alerts
 * Buttons
 * Forms
 * Line clamp
 * Pseudo filter
 * Numbered circle
 */


// Modules
// ============================================================================

@use "sass:map";


// Bootstrap mixins
// ============================================================================

@import "~bootstrap/scss/mixins";


// Backgrounds
// ============================================================================

@mixin split-background($color-a, $color-b) {
  background: linear-gradient(to right, $color-a 0%, $color-a 50%, $color-b 50%, $color-b 100%);
}

// Alerts
// ============================================================================

@mixin alert-variant(
  $background,
  $border,
  $icon: null,
  $btn-close-color: $border,
) {
  @include gradient-bg($background);
  border-color: $border;

  @if ($icon) {
    --#{$prefix}alert-icon: #{escape-svg($icon)};
    padding-left: $alert-padding-x * 2 + $alert-icon-size;

    &::after {
      position: absolute;
      top: 50%;
      left: $alert-padding-x;
      width: $alert-icon-size;
      height: $alert-icon-size;
      content: "";
      background-image: var(--#{$prefix}alert-icon);
      background-repeat: no-repeat;
      background-size: contain;
      transform: translateY(-50%);
    }
  }

  .btn-close {
    background-image: escape-svg(str-replace($alert-dismissible-icon-bg, "#{$alert-dismissible-icon-color}", "#{$btn-close-color}"));

    &:focus {
      box-shadow: 0 0 0 $btn-focus-width transparent;
    }
  }
}

// Buttons
// ============================================================================

@mixin button-variant(
  $background,
  $border: $background,
  $color: color-contrast($background),
  $hover-background: shade-color($background, $btn-hover-shade-amount),
  $hover-border: $hover-background,
  $hover-color: color-contrast($hover-background),
  $focus-background: tint-color($background, $btn-focus-tint-amount),
  $focus-color: color-contrast($focus-background),
  $active-background: shade-color($background, $btn-active-shade-amount),
  $active-border: $active-background,
  $active-color: color-contrast($active-background),
  $disabled-background: $gray-200,
  $disabled-border: $disabled-background,
  $disabled-color: #afbacc
) {
  --#{$prefix}btn-color: #{$color};
  --#{$prefix}btn-bg: #{$background};
  --#{$prefix}btn-border-color: #{$border};
  --#{$prefix}btn-hover-color: #{$hover-color};
  --#{$prefix}btn-hover-bg: #{$hover-background};
  --#{$prefix}btn-hover-border-color: #{$hover-border};
  --#{$prefix}btn-focus-color: #{$focus-color};
  --#{$prefix}btn-focus-bg: #{$focus-background};
  --#{$prefix}btn-focus-border-color: #{$white};
  --#{$prefix}btn-focus-shadow-rgb: #{to-rgb($focus-background)};
  --#{$prefix}btn-active-color: #{$active-color};
  --#{$prefix}btn-active-bg: #{$active-background};
  --#{$prefix}btn-active-border-color: #{$active-border};
  --#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};
  --#{$prefix}btn-disabled-color: #{$disabled-color};
  --#{$prefix}btn-disabled-bg: #{$disabled-background};
  --#{$prefix}btn-disabled-border-color: #{$disabled-border};
  --#{$prefix}btn-disabled-opacity: 1;
}

@mixin button-outline-variant(
  $color,
  $hover-color: shade-color($color, $btn-hover-shade-amount),
  $focus-background: tint-color($color, $btn-focus-tint-amount),
  $focus-shadow-color: tint-color($color, $btn-focus-tint-amount),
  $active-background: shade-color($color, $btn-active-shade-amount),
  $active-border: $active-background,
  $active-color: color-contrast($active-background)
) {
  --#{$prefix}btn-color: #{$color};
  --#{$prefix}btn-border-color: #{$color};
  --#{$prefix}btn-hover-color: #{$hover-color};
  --#{$prefix}btn-hover-bg: transparent;
  --#{$prefix}btn-hover-border-color: #{$hover-color};
  --#{$prefix}btn-focus-color: #{$color};
  --#{$prefix}btn-focus-bg: #{$focus-background};
  --#{$prefix}btn-focus-border-color: #{$white};
  --#{$prefix}btn-focus-shadow-rgb: #{to-rgb($focus-shadow-color)};
  --#{$prefix}btn-active-color: #{$active-color};
  --#{$prefix}btn-active-bg: #{$active-background};
  --#{$prefix}btn-active-border-color: #{$active-border};
  --#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};
  --#{$prefix}btn-disabled-color: #{$color};
  --#{$prefix}btn-disabled-bg: transparent;
  --#{$prefix}btn-disabled-border-color: #{$color};
  --#{$prefix}gradient: none;
}

@mixin button-size($padding-y, $padding-x, $font-size, $border-radius) {
  min-height: calc($padding-y * 2 + $btn-border-width * 2 + $font-size * $btn-line-height);
  padding: $padding-y $padding-x;

  @include font-size($font-size);
  @include border-radius($border-radius, 0);

  .btn-icon {
    width: $icon-size;
    height: $icon-size;
    font-size: $icon-size;
  }
}


// Forms
// ============================================================================

@mixin form-validation-state(
  $state,
  $color,
  $icon,
  $tooltip-color: color-contrast($color),
  $tooltip-bg-color: rgba($color, $form-feedback-tooltip-opacity),
  $border-color: $color
) {
  .#{$state}-feedback {
    display: none;
    width: 100%;
    margin-top: $form-feedback-margin-top;

    @include font-size($form-feedback-font-size);
    font-style: $form-feedback-font-style;
    color: $color;
    padding-inline: add($input-padding-x, $input-border-width);
  }

  .#{$state}-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: $form-feedback-tooltip-padding-y $form-feedback-tooltip-padding-x;
    margin-top: .1rem;

    @include font-size($form-feedback-tooltip-font-size);
    line-height: $form-feedback-tooltip-line-height;
    color: $tooltip-color;
    background-color: $tooltip-bg-color;

    @include border-radius($form-feedback-tooltip-border-radius);
  }

  @include form-validation-state-selector($state) {
    ~ .#{$state}-feedback,
    ~ .#{$state}-tooltip {
      display: block;
    }
  }

  .form-control {
    @include form-validation-state-selector($state) {
      border-color: $border-color;

      @if $enable-validation-icons {
        padding-right: $input-feedback-icon-padding-end;
        background-image: escape-svg($icon);
        background-repeat: no-repeat;
        background-position: right $input-padding-x center;
        background-size: $input-feedback-icon-size;

        &.form-control-end-adornment {
          padding-right: $input-feedback-icon-padding-end + $form-adornment-icon-size + spacer(2);
          background-position: right $input-padding-x + $form-adornment-icon-size + spacer(2) center;
        }
      }

      &:focus {
        border-color: $border-color;
        outline-color: $border-color;
      }
    }
  }

  textarea.form-control {
    @include form-validation-state-selector($state) {
      @if $enable-validation-icons {
        padding-right: $input-feedback-icon-padding-end;
        background-position: top $input-height-inner-quarter right $input-padding-x;
      }
    }
  }

  .form-select {
    @include form-validation-state-selector($state) {
      border-color: $border-color;

      @if $enable-validation-icons {
        &:not([multiple]):not([size]),
        &:not([multiple])[size="1"] {
          --#{$prefix}form-select-bg-icon: #{escape-svg($icon)};
          padding-right: $form-select-feedback-icon-padding-end;
          background-position: $form-select-bg-position, $form-select-feedback-icon-position;
          background-size: $form-select-bg-size, $form-select-feedback-icon-size;
        }
      }

      &:focus {
        border-color: $border-color;
        outline-color: $border-color;
      }
    }
  }

  .form-control-color {
    @include form-validation-state-selector($state) {
      @if $enable-validation-icons {
        width: add($form-color-width, $input-height-inner);
      }
    }
  }

  .form-check-input {
    @include form-validation-state-selector($state) {
      border-color: $border-color;

      &:checked {
        background-color: $color;
      }

      &:focus {
        outline-color: $border-color;
      }

      ~ .form-check-label {
        color: $color;
      }
    }
  }

  .form-check-inline .form-check-input {
    ~ .#{$state}-feedback {
      margin-left: .5em;
    }
  }

  .input-group {
    > .form-control:not(:focus),
    > .form-select:not(:focus),
    > .form-floating:not(:focus-within) {
      @include form-validation-state-selector($state) {
        @if $state == "valid" {
          z-index: 3;
        } @else if $state == "invalid" {
          z-index: 4;
        }
      }
    }
  }
}


// Line clamp
// ============================================================================

@mixin line-clamp($lines) {
  display: -webkit-box !important; // stylelint-disable-line declaration-no-important, value-no-vendor-prefix
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical !important; // stylelint-disable-line declaration-no-important
  overflow: hidden !important; // stylelint-disable-line declaration-no-important
  text-overflow: ellipsis;
}


// Pseudo filter
// ============================================================================

@mixin pseudo-filter(
  $filter-color: $overlay-filter-color,
  $filter-opacity: .2,
  $pseudo-selector: before,
  $selector: null,
  $hover-parent-selector: null,
) {
  position: relative;

  &::#{$pseudo-selector} {
    position: absolute;
    z-index: 1;
    content: "";
    background: $filter-color;
    border-radius: inherit;
    opacity: 0;
    transition: opacity .25s ease-in-out;
    inset: 0;
  }

  @if ($hover-parent-selector and $selector) {
    @at-root #{unquote($hover-parent-selector)}:hover #{unquote($selector)}::#{$pseudo-selector} {
      opacity: $filter-opacity;
    }
  } @else {
    &:hover::#{$pseudo-selector} {
      opacity: $filter-opacity;
    }
  }
}


// Numbered circle
// ============================================================================

@mixin numbered-circle(
  $color: $numbered-circle-color,
  $background: $numbered-circle-bg,
  $font-size: $numbered-circle-font-size,
  $size: $numbered-circle-size,
) {
  --numbered-circle-color: #{$color};
  --numbered-circle-bg: #{$background};
  --numbered-circle-font-size: #{$font-size};
  --numbered-circle-size: #{$size};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--numbered-circle-size);
  height: var(--numbered-circle-size);
  font-size: var(--numbered-circle-font-size);
  font-weight: $font-weight-bold;
  color: var(--numbered-circle-color);
  background-color: var(--numbered-circle-bg);
  border-radius: 50%;
}
