/*
 * Content:
 *
 * Gradient decoration
 * Card link
 */

// Gradient decoration
// ============================================================================
.gradient-decoration {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: rem(287px);
  background: linear-gradient(312.04deg, $primary 16.01%, $secondary 98.96%);
  border-bottom-right-radius: rem(105px);
  border-bottom-left-radius: rem(105px);
}


// Card link
// ============================================================================
.lifeplan-docs-card-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: rem(184px);
  padding: spacer(4);
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
  color: $black;
  text-decoration: none;
  background-color: $white;
  border: $border-width solid $gray-200;
  border-radius: $border-radius-lg;
  gap: spacer(5);
}
