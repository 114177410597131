/*
 * Content:
 *
 * Banner
 * Banner title
 * Banner body
 */


// Banner
// ============================================================================

.banner {
  padding: spacer(5) spacer(6);
  background-color: $banner-bg;
  background-image: var(--banner-img);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: $banner-border-radius;
  aspect-ratio: auto;
}


// Banner title
// ============================================================================

.banner-title {
  margin-bottom: 0;
  font-size: $banner-font-size;
  font-weight: $font-weight-bold;
  color: $banner-color;
}


// Banner body
// ============================================================================

.banner-body {
  height: 100%;
  padding: spacer(5) spacer(4);
  font-size: $font-size-lg;
  color: $banner-color;
  background-color: rgba(0 0 0 / 25%);
  border-left: 2px solid $secondary;
  border-radius: $border-radius-lg;
  backdrop-filter: blur(10px);

  @include media-breakpoint-up(lg) {
    max-width: rem(766px);
  }
}
