/*
 * Content:
 *
 * Breadcrumb by menu_tags
 * Breadcrumb item
 * Breadcrumb item back
 * Breadcrumb text
 */


// Breadcrumb by menu_tags
// ============================================================================

.breadcrumb {
  margin-top: $breadcrumb-margin-bottom;

  li:not(.breadcrumb-item) {
    @extend .breadcrumb-item; // stylelint-disable-line scss/at-extend-no-missing-placeholder
  }

  li:not(.breadcrumb-item):has(.active) {
    @extend .active; // stylelint-disable-line scss/at-extend-no-missing-placeholder
  }

  .separator {
    display: none;
  }
}


// Breadcrumb item
// ============================================================================

.breadcrumb-item {
  display: none;
  font-weight: $font-weight-bold;

  a {
    padding: spacer(2) spacer(1);
  }

  @include media-breakpoint-up(lg) {
    display: list-item;
  }
}


// Breadcrumb item back
// ============================================================================

.breadcrumb-item-back {
  display: list-item;

  &::before {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    &::before {
      display: inline;
    }
  }
}


// Breadcrumb text
// ============================================================================

.breadcrumb-text {
  display: none;

  @include media-breakpoint-up(lg) {
    display: inline;
  }
}


.breadcrumb-text-back {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}
