/*
 * Content:
 *
 * Modal header
 * Modal 2xl
 */


// Modal header
// ============================================================================

.modal-header {
  .btn-close {
    padding: $btn-close-padding-y $btn-close-padding-x;
    margin: 0 0 0 auto;
  }
}

// Modal 2xl
// ============================================================================
.modal-2xl {
  --#{$prefix}modal-width: #{$modal-sm};

  @include media-breakpoint-up(md) {
    --#{$prefix}modal-width: #{$modal-md};
  }

  @include media-breakpoint-up(lg) {
    --#{$prefix}modal-width: #{$modal-lg};
  }

  @include media-breakpoint-up(xl) {
    --#{$prefix}modal-width: #{$modal-xl};
  }

  @include media-breakpoint-up(2xl) {
    --#{$prefix}modal-width: #{$modal-2xl};
  }
}
