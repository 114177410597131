/*
 * Content:
 *
 * List style type
 */


// List style type
// ============================================================================

.list-style-type-child-disc > ul {
  list-style-type: disc !important; //stylelint-disable-line declaration-no-important
}
