/*
 * Content:
 *
 * Margin last child 0
 */

// Margin last child 0
// ============================================================================

.mb-last-child-0 > :last-child {
  margin-bottom: 0 !important; // stylelint-disable-line declaration-no-important
}
