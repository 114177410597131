/*
 * Content:
 *
 * Carousel (default by bootstrap)
 * Cards carrousel
 * Related news carrousel
 */


@use "sass:map";

// Carousel (default by bootstrap)
// ============================================================================
.cms-carousel {
  .carousel-indicators {
    position: relative;
    display: flex;
    gap: spacer(1);
    margin-top: spacer(5);

    button {
      $indicator-size: 1rem;
      width: $indicator-size;
      height: $indicator-size;
      cursor: pointer;
      background-color: $gray-400;
      border: none;
      border-radius: 100%;

      &.active {
        background-color: $primary;
      }
    }
  }
}


// Cards carrousel
// ============================================================================
.cards-carousel {
  .navigation-button {
    $navigation-button-size: 48px;
    $navigation-buttons-z-index: 20;
    $navigation-buttons-spacing: -4rem;
    $navigation-button-icon-size: 32px;

    position: absolute;
    top: 0;
    bottom: 0;

    z-index: $navigation-buttons-z-index;
    width: $navigation-button-size;
    height: $navigation-button-size;
    padding: 0;
    margin: auto 0;
    background-color: map.get($theme-colors-light, "secondary");
    border: none;

    border-radius: 50%;


    &[data-navigation="back"] {
      left: $navigation-buttons-spacing;

      img {
        width: $navigation-button-icon-size;
        height: $navigation-button-icon-size;
        margin-right: spacer(1);
        margin-bottom: spacer(1);
      }

      @include media-breakpoint-down(lg) {
        left: 0;
      }
    }

    &[data-navigation="next"] {
      right: $navigation-buttons-spacing;

      img {
        width: $navigation-button-icon-size;
        height: $navigation-button-icon-size;
        margin-bottom: spacer(1);
        margin-left: spacer(1);
      }

      @include media-breakpoint-down(lg) {
        right: 0;
      }
    }
  }

  .slide {
    $max-img-height: 185px;

    img {
      max-height: $max-img-height;
    }
  }
}
