.aside {
  max-width: 350px;
  height: 100%;
  background-color: #ebeff5;

  .banner {
    width: 100%;
    padding: spacer(2);
    padding-right: spacer(3);
    margin-bottom: 0;
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;
    color: $white;
    background-color: $secondary;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
