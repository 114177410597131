/*
 * Content:
 *
 * Modules
 * Columns
 */

// Modules
// ============================================================================

@use "sass:map";


// Columns
// ============================================================================

@if $enable-grid-classes {
  @each $breakpoint in map.keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      .col#{$infix}-2_4 {
        @include make-col(2.4, $grid-columns);
      }

      .col#{$infix}-5_5 {
        @include make-col(5.5, $grid-columns);
      }

      .col#{$infix}-6_5 {
        @include make-col(6.5, $grid-columns);
      }
    }
  }
}
