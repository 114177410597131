/*
 * Content:
 *
 * Modules
 * Control
 * Option
 * Menu
 */


// Modules
// ============================================================================

@use "sass:map";


/* stylelint-disable selector-class-pattern, declaration-no-important */
// Control
// ============================================================================

.react-select-container {
  .react-select__control {
    border-color: $form-select-border-color;
    box-shadow: $form-select-box-shadow;

    &:hover:not(:disabled) {
      border-color: $form-select-border-color;
      box-shadow: $form-select-hover-box-shadow;
    }

    &.react-select__control--is-focused,
    &.react-select__control--menu-is-open {
      outline: $form-select-focus-outline !important;
      box-shadow: $form-select-box-shadow;

      &,
      &:hover {
        border-color: $form-select-focus-border-color;
      }
    }

    &.react-select__control--menu-is-open {
      --#{$prefix}form-select-bg-img: #{escape-svg($form-select-open-indicator)};
    }

    &.react-select__control--is-disabled {
      background-color: $form-select-disabled-bg;
      border-color: $form-select-disabled-border-color;
    }

    @each $state, $data in $form-validation-states {
      $border-color: map.get($data, "border-color");

      &.is-#{$state} {
        &,
        &:hover,
        &.react-select__control--is-focused,
        &.react-select__control--is-focused:hover,
        &.react-select__control--menu-is-open,
        &.react-select__control--menu-is-open:hover {
          border-color: $border-color;
        }

        &.react-select__control--is-focused,
        &.react-select__control--menu-is-open {
          outline-color: $border-color !important;
        }
      }
    }

    .react-select__value-container {
      padding: 0;

      .react-select__placeholder,
      .react-select__single-value {
        margin: 0;
      }

      .react-select__placeholder {
        color: $input-placeholder-color;
      }
    }
  }

  .react-select__single-value {
    color: $form-select-color;

    &.react-select__single-value--is-disabled {
      color: $form-select-disabled-color;
    }
  }

  .react-select__control.form-control-sm {
    padding: spacer(3);
    font-size: $font-size-base;
    border-radius: spacer(2);
  }

  .react-select__input-container {
    padding: 0;
    margin: 0;
  }
}


// Option
// ============================================================================

.react-select__menu-portal .react-select__option {
  padding: $form-select-menu-item-padding;
  font-size: $form-select-menu-item-font-size;
  font-weight: $font-weight-medium;
  color: $form-select-menu-item-color;
  background: $form-select-menu-item-bg;

  &.react-select__option--is-focused {
    color: $form-select-menu-item-hover-color;
    background: $form-select-menu-item-hover-bg;
  }

  &.react-select__option--is-selected {
    color: $form-select-menu-item-active-color;
    background: $form-select-menu-item-active-bg;
  }
}


// Menu
// ============================================================================

.react-select__menu-portal .react-select__menu {
  z-index: $zindex-dropdown;
  border: $form-select-menu-border;
  border-radius: $form-select-menu-border-radius;
  box-shadow: $form-select-menu-box-shadow;
}

.react-select__menu-portal .react-select__menu-list {
  padding-block: $form-select-menu-padding-y;
}
/* stylelint-enable selector-class-pattern, declaration-no-important */
