/*
 * Content:
 *
 * Badge
 */


// Badge
// ============================================================================
.badge {
  width: 100%;
  max-width: $badge-width;
  padding: spacer(2) spacer(3);
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
  color: $white;
  border-radius: 2rem;

  a {
    color: inherit;
    text-decoration: none;
  }
}
