/*
 * Content:
 *
 * Modules
 * Button
 * Button variants
 * Button link
 * Button form adornment
 * Button disabled
 * Button like
 */


// Modules
// ============================================================================

@use "sass:map";


// Button
// ============================================================================

.btn {
  --#{$prefix}btn-focus-box-shadow: 0 0 0 #{$btn-focus-width} rgb(var(--#{$prefix}btn-focus-shadow-rgb), 1); // stylelint-disable-line color-function-notation
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: spacer(2);

  &:focus-visible {
    color: var(--#{$prefix}btn-focus-color);

    @include gradient-bg(var(--#{$prefix}btn-focus-bg));
    border-color: var(--#{$prefix}btn-focus-border-color);
  }

  .btn-check:focus-visible + & {
    border-color: var(--#{$prefix}btn-focus-border-color);
  }
}

.btn-fake {
  --#{$prefix}btn-padding-x: #{$btn-padding-x};
  --#{$prefix}btn-padding-y: #{$btn-padding-y};
  --#{$prefix}btn-font-family: #{$btn-font-family};

  @include rfs($btn-font-size, --#{$prefix}btn-font-size);

  --#{$prefix}btn-font-weight: #{$btn-font-weight};
  --#{$prefix}btn-line-height: #{$btn-line-height};
  --#{$prefix}btn-color: #{$btn-color};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-border-width: #{$btn-border-width};
  --#{$prefix}btn-border-color: transparent;
  --#{$prefix}btn-border-radius: #{$btn-border-radius};
  --#{$prefix}btn-box-shadow: #{$btn-box-shadow};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: spacer(2);
  padding: var(--#{$prefix}btn-padding-y) var(--#{$prefix}btn-padding-x);
  font-family: var(--#{$prefix}btn-font-family);

  @include font-size(var(--#{$prefix}btn-font-size));
  font-weight: var(--#{$prefix}btn-font-weight);
  line-height: var(--#{$prefix}btn-line-height);
  color: var(--#{$prefix}btn-color);
  text-align: center;
  text-decoration: if($link-decoration == none, null, none);
  white-space: $btn-white-space;
  vertical-align: middle;
  border: var(--#{$prefix}btn-border-width) solid var(--#{$prefix}btn-border-color);

  @include border-radius(var(--#{$prefix}btn-border-radius));
  @include gradient-bg(var(--#{$prefix}btn-bg));
  @include box-shadow(var(--#{$prefix}btn-box-shadow));
  @include transition($btn-transition);
}


// Button variants
// ============================================================================

@each $color, $value in $theme-colors {
  $hover-value: map.get($theme-colors-hover, $color);
  $focus-value: map.get($theme-colors-focus, $color);
  $active-value: map.get($theme-colors-active, $color);
  $light-value: map.get($theme-colors-light, $color);

  // Set default values in case any is null
  $hover-value: shade-color($value, $btn-hover-shade-amount) !default;
  $focus-value: tint-color($value, $btn-focus-tint-amount) !default;
  $active-value: shade-color($value, $btn-active-shade-amount) !default;
  $light-value: tint-color($value, $btn-focus-tint-amount + 20%) !default;

  .btn-#{$color} {
    @include button-variant(
      $background: $value,
      $hover-background: $hover-value,
      $focus-background: $focus-value,
      $active-background: $active-value
    );
  }

  .btn-outline-#{$color} {
    @include button-outline-variant(
      $color: $value,
      $hover-color: $hover-value,
      $focus-background: $light-value,
      $focus-shadow-color: $focus-value,
      $active-background: $active-value,
    );
  }

  .btn-radio-#{$color} {
    @include button-outline-variant(
      $color: $value,
      $hover-color: $hover-value,
      $focus-background: $light-value,
      $active-background: $value,
    );
  }
}

.btn-skyblue-middle {
  @include button-variant($skyblue-middle, $color: $white);
}


// Button link
// ============================================================================

.btn-link {
  --#{$prefix}btn-font-weight: #{$btn-font-weight};
  --#{$prefix}btn-focus-color: #{map.get($theme-colors-focus, "primary")};
  --#{$prefix}btn-focus-border-color: transparent;
  --#{$prefix}btn-active-color: #{map.get($theme-colors-active, "primary")};
  --#{$prefix}btn-active-bg: #{map.get($theme-colors-light, "primary")};
  padding: $btn-padding-y spacer(2);

  .btn-check:checked + &,
  :not(.btn-check) + &:active,
  &:first-child:active,
  &.active,
  &.show {
    &:focus-visible {
      --#{$prefix}btn-focus-box-shadow: none;
    }
  }
}

// Button form adornment
// ============================================================================

.btn-form-adornment {
  --#{$prefix}btn-padding-y: #{spacer(2)};
  --#{$prefix}btn-padding-x: #{spacer(2)};
  line-height: 1;
  border-radius: 50%;

  img {
    width: $form-adornment-icon-size;
    height: $form-adornment-icon-size;
  }
}


// Button disabled
// ============================================================================

.btn-disabled {
  &:disabled {
    color: $gray-700;
    background-color: $gray-200;
    border-color: transparent;
  }
}


// Navigator button
// ============================================================================

.navigator-buttons {
  display: flex;
  flex-direction: column;
  gap: spacer(4);

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    justify-content: center;

    > .btn {
      min-width: 219px;
    }
  }
}
