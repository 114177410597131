/*
 * Content:
 *
 * Base Popover
 */

.base-popover {
  padding: spacer(3);
  color: $white;
  background-color: $primary;
  border-radius: $border-radius;
  box-shadow: $box-shadow;

  .title {
    margin-bottom: spacer(2);
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    color: $light;
    text-align: start;
  }

  .content {
    margin-bottom: 0;
    font-size: $font-size-xs;
    font-weight: $font-weight-normal;
    text-align: start;
  }
}
