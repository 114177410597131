/*
 * Content:
 *
 * Accordion button
 * Accordion item
 * Accordion pills
 * Accordion form
 * Accordion outline
 */


// Accordion button
// ============================================================================

.accordion-button {
  font-weight: $font-weight-bold;

  &:not(:focus):not(.collapsed) {
    box-shadow: none;
  }

  &:disabled {
    --#{$prefix}accordion-btn-bg: #{$gray-200};
    --#{$prefix}accordion-btn-icon: #{escape-svg(str-replace($accordion-button-icon, "#{$accordion-icon-color}", "#{$body-color}"))};
  }
}


// Accordion item
// ============================================================================

.accordion-item {
  border-top: none;
  border-right: none;
  border-left: none;
  box-shadow: $box-shadow-sm;
}


// Accordion pills
// ============================================================================

.accordion-pills {
  .accordion-item,
  .accordion-button.collapsed {
    @include border-radius(var(--#{$prefix}accordion-border-radius));
  }

  .accordion-button {
    @include border-top-radius(var(--#{$prefix}accordion-border-radius));
  }

  .accordion-item {
    border-bottom: none;

    & + .accordion-item {
      margin-top: spacer(5);
    }
  }
}


// Accordion form
// ============================================================================

.accordion-form {
  --#{$prefix}accordion-btn-bg: #{$gray-200};
}


// Accordion outline
// ============================================================================

.accordion-outline {
  --#{$prefix}accordion-outline-color: #{$primary};
  --#{$prefix}accordion-color: #{$primary};
  --#{$prefix}accordion-bg: var(--#{$prefix}body-bg);
  --#{$prefix}accordion-border-width: 2px;
  --#{$prefix}accordion-border-color: var(--#{$prefix}accordion-outline-color);
  --#{$prefix}accordion-border-radius: #{rem(32px)};
  --#{$prefix}accordion-inner-border-radius: calc(var(--#{$prefix}accordion-border-radius) - var(--#{$prefix}accordion-border-width));
  --#{$prefix}accordion-btn-padding-x: #{spacer(6)};
  --#{$prefix}accordion-btn-bg: var(--#{$prefix}accordion-bg);
  --#{$prefix}accordion-btn-icon-width: #{rem(30px)};
  --#{$prefix}accordion-body-padding-x: var(--#{$prefix}accordion-btn-padding-x);
  --#{$prefix}accordion-active-bg: var(--#{$prefix}accordion-bg);

  .accordion-item,
  .accordion-button.collapsed {
    @include border-radius(var(--#{$prefix}accordion-border-radius));
  }

  .accordion-button {
    @include border-top-radius(var(--#{$prefix}accordion-border-radius));
    font-size: $font-size-lg;
    color: var(--#{$prefix}accordion-outline-color);
    gap: spacer(2);

    // Focus style will be managed in .accordion-item
    &:focus {
      box-shadow: none;
    }
  }

  .accordion-body {
    padding-top: 0;
  }

  .accordion-item {
    border: var(--#{$prefix}accordion-border-width) solid var(--#{$prefix}accordion-outline-color);

    + .accordion-item {
      margin-top: spacer(4);
    }

    &:focus-within {
      outline: 1px solid var(--#{$prefix}accordion-outline-color);
    }
  }

  .numbered-circle {
    --numbered-circle-color: #{$white};
    --numbered-circle-bg: var(--#{$prefix}accordion-outline-color);
  }
}
