@use "sass:map";

/*
 * Simulation container: target to the section where simulation will be rendered. Each iframe for simulation should be wrapped by a simulation container.
 * Simulation classes: since each simulation `iframe` should use its respectively variables for `height`; simulation classes were created in order to target the specific type of simulation and its dimensions.
 * Simulation layout
 */

// Simulation container
// ============================================================================

.simulation-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
  }

  // Simulation classes
  // ============================================================================

  .mutual-funds,
  .provisional-savings,
  .apv-life,
  .full-flexible,
  .insured-university {
    min-height: 100%;

    &.empty {
      height: 600px;

      @include media-breakpoint-up(lg) {
        height: 100%;
      }
    }
  }

  .apv {
    height: 1350px;

    @include media-breakpoint-up(sm) {
      height: 100%;
    }
  }

  .mutual-funds {
    height: 930px;

    @include media-breakpoint-up(sm) {
      height: 843px;
    }

    @include media-breakpoint-up(md) {
      height: 583px;
    }

    @include media-breakpoint-up(lg) {
      height: 843px;
    }

    @include media-breakpoint-up(xl) {
      height: 583px;
    }
  }

  .insurance-with-savings {
    height: 1350px;

    @include media-breakpoint-up(sm) {
      height: 100%;
    }
  }

  .provisional-savings {
    // `.ideal_pension` and `.savings_capacity` are variants for the current simulation
    // They have different sizes because they render different content for each one

    // Ideal pension renders an extra card which increments the expected height drastically

    // This classes are given in the through the parameter `iframeAttributes.classNames` in the
    // `simulationManager.render()` function call.
    /* stylelint-disable selector-class-pattern */
    &.ideal_pension {
      height: 2084px;

      @include media-breakpoint-up(sm) {
        height: 1776px;
      }

      @include media-breakpoint-up(md) {
        height: 1319px;
      }

      @include media-breakpoint-up(lg) {
        height: 1776px;
      }

      @include media-breakpoint-up(xl) {
        height: 1424px;
      }

      @include media-breakpoint-up(2xl) {
        height: 1337px;
      }

      @include media-breakpoint-up(3xl) {
        height: 1301px;
      }
    }

    &.savings_capacity {
      height: 1878px;

      @include media-breakpoint-up(sm) {
        height: 1579px;
      }

      @include media-breakpoint-up(md) {
        height: 1113px;
      }

      @include media-breakpoint-up(lg) {
        height: 1579px;
      }

      @include media-breakpoint-up(xl) {
        height: 1236px;
      }

      @include media-breakpoint-up(2xl) {
        height: 1131px;
      }

      @include media-breakpoint-up(3xl) {
        height: 1095px;
      }
    }
    /* stylelint-enable selector-class-pattern */
  }

  .apv-life {
    /* stylelint-disable selector-class-pattern */
    &.ideal_pension {
      height: 1973px;

      @include media-breakpoint-up(sm) {
        height: 1660px;
      }

      @include media-breakpoint-up(md) {
        height: 1223px;
      }

      @include media-breakpoint-up(lg) {
        height: 1660px;
      }

      @include media-breakpoint-up(xl) {
        height: 1301px;
      }

      @include media-breakpoint-up(2xl) {
        height: 1223px;
      }
    }

    &.savings_capacity {
      height: 1767px;

      @include media-breakpoint-up(sm) {
        height: 1463px;
      }

      @include media-breakpoint-up(md) {
        height: 1026px;
      }

      @include media-breakpoint-up(lg) {
        height: 1463px;
      }

      @include media-breakpoint-up(xl) {
        height: 1095px;
      }

      @include media-breakpoint-up(2xl) {
        height: 1026px;
      }
    }
    /* stylelint-enable selector-class-pattern */
  }

  .full-flexible {
    height: 1652px;

    @include media-breakpoint-up(sm) {
      height: 1541px;
    }

    @include media-breakpoint-up(md) {
      height: 958px;
    }

    @include media-breakpoint-up(lg) {
      height: 1541px;
    }

    @include media-breakpoint-up(xl) {
      height: 958px;
    }
  }

  .insured-university {
    height: 1726px;

    @include media-breakpoint-up(sm) {
      height: 1568px;
    }

    @include media-breakpoint-up(md) {
      height: 958px;
    }

    @include media-breakpoint-up(lg) {
      height: 1568px;
    }

    @include media-breakpoint-up(xl) {
      height: 990px;
    }

    @include media-breakpoint-up(2xl) {
      height: 979px;
    }
  }
}


// Simulation layout
// ============================================================================

.simulation-layout {
  display: grid;
  grid-template-rows: repeat(2, auto);
  gap: spacer(5);
  align-items: flex-start;

  @include media-breakpoint-up(lg) {
    grid-template-rows: auto;
    grid-template-columns: 2fr 3fr;
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: 2fr minmax(657px, 3fr);
  }
}
