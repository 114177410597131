/*
 * Content:
 *
 * Html
 * Main
 * Page loading
 */


// Html
// ============================================================================

html {
  position: relative;
  min-height: 100%;
  font-size: $font-size-base-in-px; // 62.5% = 10px | 100% = 16px

  &.cms-toolbar-expanded .page-header {
    top: 46px;
  }
}


// Main
// ============================================================================

main {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

// Page loading
// ============================================================================

body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .page-loading-icon {
    position: relative;
    left: 10px;
    display: none;
  }

  &.wait {
    cursor: wait !important; // stylelint-disable-line declaration-no-important

    .page-loading-icon {
      display: inline-block;
    }
  }
}
