/*
 * Content:
 *
 * Modules
 * Table content
 */

// Modules
// ============================================================================

@use "sass:map";


// Table content
// ============================================================================

.table-content {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: spacer(4) 0;

  @include list-unstyled();
  padding: spacer(4);
  border: map.get($border-widths, 2) solid $secondary;
  border-radius: $border-radius-lg;

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.table-content-item {
  &::after {
    position: absolute;
    left: 50%;
    display: block;
    width: 77%;
    content: "";
    border-top: 1px solid $table-border-color;
    transform: translate(-50%, spacer(2));
  }

  &:last-child::after {
    display: none;
  }

  @include media-breakpoint-up(sm) {
    &::after {
      display: none;
    }

    &:nth-child(2n) {
      border-left: none;
    }

    &:nth-child(3n - 1),
    &:nth-child(3n) {
      border-left: map.get($border-widths, 2) solid map.get($theme-colors-focus, "secondary");
    }

    &:nth-child(3n):not(:last-child) {
      &::after {
        display: block;
        width: min(480px, 100%);
      }
    }
  }
}
