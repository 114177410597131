/*
 * Content:
 *
 * Dim
 */


// Dim
// ============================================================================

.dim {
  transition: background 2s;
}
