/*
 * Content:
 *
 * Nav pills
 */

// Nav pills
// ============================================================================

.nav-pills {
  --#{$prefix}nav-link-color: #{$body-color};
  --#{$prefix}nav-link-hover-color: #{$body-color};
  --#{$prefix}nav-link-font-size: #{$font-size-sm};
  --#{$prefix}nav-link-padding-y: #{spacer(2)};
  --#{$prefix}nav-link-padding-x: #{spacer(2)};
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: spacer(1);
  background-color: $light-disable;
  border-radius: $nav-pills-border-radius;
  gap: spacer(1);

  .nav-link {
    width: 100%;
    min-width: $nav-pill-min-width;
    text-align: center;
    border: $nav-pill-border-width solid transparent;

    &.active {
      font-weight: $font-weight-bold;
      border-color: $nav-pill-border-color;
      box-shadow: $nav-pill-box-shadow;
    }
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
    width: max-content;
  }
}
