/*
 * Content:
 *
 * Tooltip
 * Tooltip inner
 */


// Tooltip
// ============================================================================

.tooltip {
  background-color: $white;
  filter: drop-shadow(0 .25rem .75rem rgb(21 31 56 / 10%));
  border: 1px solid $gray-300;
  border-radius: 4px;
}

// Tooltip inner
// ============================================================================

.tooltip-inner {
  margin: 8px;
  text-align: left;
}

.bs-tooltip-bottom .arrow::after {
  position: absolute;
  bottom: 0;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000;
  transform: translateY(3px);
}

.tooltip-qm {
  float: left;
  margin: -2px 0 3px 4px;
  font-size: 12px;
}
