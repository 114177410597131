/*
 * Content:
 *
 * Modules
 * Popup menu
 * Popup menu list
 * Popup menu item
 */

// Modules
// ============================================================================

@use "sass:map";

// Popup menu
// ============================================================================

.popup-menu {
  &.offcanvas {
    top: $popup-menu-top-offset;
    right: 0;
    left: unset;
    width: $popup-menu-width;
    height: max-content;
    box-shadow: $box-shadow-sm;
  }

  .offcanvas-body {
    padding-top: spacer(2);
  }
}


// Popup menu list
// ============================================================================

.popup-menu-list {
  display: flex;
  flex-direction: column;
  gap: spacer(2);
  margin-bottom: 0;
}


// Popup menu item
// ============================================================================

.popup-menu-item {
  padding: spacer(2) spacer(4);
  color: $text-subtitle;
  background-color: map.get($theme-colors-light, "info");
  border-radius: $border-radius;
  box-shadow: $box-shadow-sm;
}
