/*
 * Content:
 *
 * Navbar
 * Navbar brand
 * Navbar toggler
 */

// Navbar
// ============================================================================
.navbar {
  height: $navbar-height;

  @include media-breakpoint-up(lg) {
    height: $navbar-lg-height;
  }
}


// Navbar brand
// ============================================================================

.navbar-brand {
  padding: 0;
}


// Navbar toggler
// ============================================================================

.navbar-toggler {
  padding: $navbar-toggler-padding-y px($navbar-toggler-padding-x);
  font-size: px($navbar-toggler-font-size);
  border: none;
}
