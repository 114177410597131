/*
 * Content:
 *
 * Isn modal button
 */

// Isn modal button
// ============================================================================

.isn-modal-button {
  position: fixed;
  top: 60vh;
  right: 0;
  z-index: $zindex-fixed;
  align-items: flex-start;
  padding: $isn-modal-button-padding-y $isn-modal-button-padding-x;
  font-weight: $font-weight-bold;
  text-align: left;
  border-radius: $isn-modal-button-border-radius;
  transition: $btn-transition, transform .3s;
  gap: $isn-modal-button-icon-spacing;

  @include media-breakpoint-up(xl) {
    width: $isn-modal-button-lg-width;
    padding: $isn-modal-button-lg-padding-y $isn-modal-button-lg-padding-x;
    font-size: $font-size-xl;
    transform: translateX(calc(100% - #{$isn-modal-button-lg-padding-x} - #{$isn-modal-button-lg-icon-size} - #{$isn-modal-button-icon-spacing}));

    &:hover {
      transform: translateX(0);
    }
  }
}

.isn-modal-button-text {
  display: none;

  @include media-breakpoint-up(xl) {
    display: inline;
  }
}

.isn-modal-button-icon {
  width: $isn-modal-button-icon-size;

  @include media-breakpoint-up(xl) {
    width: $isn-modal-button-lg-icon-size;
  }
}

.isn-rating-input {
  width: 66px;
  height: 66px;
  background-repeat: no-repeat;
  background-size: contain;
  appearance: none;

  &:checked {
    filter: drop-shadow($box-drop-shadow);
  }
}
