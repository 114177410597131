/*
 * Content:
 *
 * Numbered circle
 */


// Numbered circle
// ============================================================================

.numbered-circle {
  @include numbered-circle();
}
