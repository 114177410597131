/*
 * Content:
 *
 * List
 * Line clamp
 */

// List
// ============================================================================

ol,
ul {
  padding-left: spacer(5);
}


// Line clamp
// ============================================================================

@for $lines from 1 through 2 {
  .line-clamp-#{$lines} {
    @include line-clamp($lines);
  }

  .line-clamp-children-#{$lines} > * {
    @include line-clamp($lines);
  }
}
