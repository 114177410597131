/*
 * Content:
 *
 * Fixed notification
 */


// Fixed notification
// ============================================================================

.fixed-notification {
  position: fixed;
  top: $nav-link-height + 2rem;
  right: 30px;
  left: 30px;
  z-index: $zindex-tooltip + 10;

  @include media-breakpoint-up(md) {
    left: auto;
    width: 500px;
  }
}
