/*
 * Content:
 *
 * Hamburger menu
 * Hamburger menu list
 */

// Hamburger menu
// ============================================================================

.hamburger-menu {
  &.offcanvas {
    top: $hamburger-menu-top-offset;

    right: 0;
    left: unset;
    width: 100%;
    height: max-content;

    @include media-breakpoint-up(lg) {
      top: $hamburger-menu-lg-top-offset;
      width: $hamburger-menu-width;
    }
  }

  .offcanvas-body {
    padding: spacer(7) spacer(5);
  }

  & ~ .offcanvas-backdrop {
    top: $hamburger-menu-top-offset;

    @include media-breakpoint-up(lg) {
      top: $hamburger-menu-lg-top-offset;
    }
  }
}


// Hamburger menu list
// ============================================================================

.hamburger-menu-list {
  display: flex;
  flex-direction: column;
  font-size: $font-size-xl;
  gap: spacer(8);

  &:last-child {
    margin-bottom: spacer(6);
  }

  a {
    color: $text-subtitle;
    text-decoration: none;
  }
}
