/*
 * Content:
 *
 * Text plugin
 * Video plugin
 * Expanded toolbar
 */


// Text plugin
// ============================================================================

h1,
h2,
h3,
h4,
h5,
h6 {
  &.cms-plugin {
    @extend .text-break; // stylelint-disable-line scss/at-extend-no-missing-placeholder
  }
}


// Video plugin
// ============================================================================

.djangocms-video-plugin video {
  width: 100%;
}


// Expanded toolbar
// ============================================================================

html.cms-toolbar-expanded {
  .modal-dialog-scrollable {
    height: calc(100% - #{$cms-toolbar-height} - var(--bs-modal-margin) * 2);
  }

  .modal-dialog-centered {
    top: $cms-toolbar-height;
    min-height: calc(100% - #{$cms-toolbar-height} - var(--bs-modal-margin) * 2);
  }

  .popup-menu.offcanvas {
    top: calc(#{$cms-toolbar-height} + #{$popup-menu-top-offset});
  }
}
