/*
 * Content:
 *
 * Navigation Feature card link
 * Feature card link
 */


// Navigation Feature cards link
// ============================================================================

.navigation-feature-card-link {
  position: relative;
  width: 100%;
  height: 377px;
  overflow: hidden;
  border-radius: $border-radius;
  box-shadow: $box-shadow-lg;

  &::after {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: spacer(5);
    content: "";
    background-color: $card-bg;
  }

  img {
    display: block;
    width: 100%;
    height: 246px;
    object-fit: cover;
    object-position: center;
  }

  .content {
    position: relative;
    top: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 377px;
    padding: spacer(5) spacer(4);
    color: $text-subtitle;
    text-align: center;
    background-color: $card-bg;
    transition: top ease-in .2s;

    header {
      color: $primary;
    }
  }

  .description {
    margin-top: spacer(4);
    font-size: $font-size-sm;

    * {
      margin-bottom: 0;
    }
  }

  &:hover,
  :focus-visible & {
    &::after {
      display: none;
    }

    .content {
      top: -246px;
    }
  }
}

.feature-card-link {
  display: grid;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: $border-radius;
  box-shadow: $box-shadow-lg;
  grid-template-rows: 1fr;

  &:nth-child(odd) {
    justify-self: end;
  }

  &:nth-child(even) {
    justify-self: start;
  }


  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: spacer(4);
    padding-bottom: spacer(2);
    text-align: center;
    gap: spacer(2);
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: spacer(5) spacer(4);
    text-align: center;
    background-color: $white;
  }

  .title {
    margin-bottom: spacer(4);
    font-size: $font-size-lg;
    color: $primary;

    p {
      margin-bottom: 0;
    }
  }

  a.btn {
    padding-block: 8px;
  }
}
