/*
 * Content:
 *
 * Modules
 * Table
 * Table rounded
 * Table lg
 * Table tiles
 */


// Modules
// ============================================================================

@use "sass:map";


// Table
// ============================================================================

.table {
  text-align: center;

  > thead > tr > * {
    vertical-align: $table-cell-vertical-align;
    background: map.get($theme-colors-light, secondary);
    padding-block: spacer(3);
  }

  > tbody > tr > :not(:first-child) {
    border-left: $table-border-width solid $table-vertical-border-color;
  }
}


// Table rounded
// ============================================================================

.table-rounded {
  --table-rounded-border-radius: #{$table-rounded-border-radius};
  border-spacing: 0;
  border-collapse: separate;
  border-bottom: $table-border-width solid $table-border-color;
  border-bottom-right-radius: var(--table-rounded-border-radius);
  border-bottom-left-radius: var(--table-rounded-border-radius);

  > tbody > tr:last-child > * {
    border-bottom: 0;
  }

  > thead > tr:first-child > :first-child {
    border-top-left-radius: var(--table-rounded-border-radius);
  }

  > thead > tr:first-child > :last-child {
    border-top-right-radius: var(--table-rounded-border-radius);
  }

  > tbody > tr:last-child > :first-child {
    border-bottom-left-radius: var(--table-rounded-border-radius);
  }

  > tbody > tr:last-child > :last-child {
    border-bottom-right-radius: var(--table-rounded-border-radius);
  }
}


// Table lg
// ============================================================================

.table-lg {
  > tbody > tr > * {
    height: rem(85px);
  }
}


// Table tiles
// ============================================================================

// Tile
.table-tile {
  --table-tile-padding-y: #{$table-tiles-tile-padding-y};
  --table-tile-padding-x: #{$table-tiles-tile-padding-x};
  padding: var(--table-tile-padding-y) var(--table-tile-padding-x);
  font-weight: $font-weight-bold;
  color: $table-tiles-tile-color;
  text-align: center;
  background: $table-tiles-tile-bg;
  border-radius: $table-tiles-tile-border-radius;
  box-shadow: $table-tiles-tile-box-shadow;
}

.table-tile-colspan,
.table-tile-rowspan {
  position: relative;

  > * {
    position: absolute;
    inset: 0;
    padding: var(--table-tile-padding-y) var(--table-tile-padding-x);
  }
}

.table-tile-rowspan-content {
  display: flex;
  flex-direction: column;
  gap: var(--table-tiles-row-gap);

  > * {
    flex: 1;
    padding-top: var(--table-tile-padding-y);
  }
}

.table-tile-radio {
  width: 0;
  padding-inline: spacer(4);
}

// Tile sizes
.table-tile-sm {
  width: $table-tiles-tile-sm-width;
  min-width: $table-tiles-tile-sm-width;
  max-width: $table-tiles-tile-sm-width;
}

.table-tile-xs {
  --table-tile-padding-x: 0;
  width: $table-tiles-tile-xs-width;
  min-width: $table-tiles-tile-xs-width;
  max-width: $table-tiles-tile-xs-width;
}

// Divider
.table-tiles-divider {
  position: absolute;
  right: calc(var(--table-tiles-column-gap) * 1.5);
  left: calc(var(--table-tiles-column-gap) * 1.5);
  height: $table-tiles-divider-height;
  margin-top: calc(var(--table-tiles-row-gap) * .5);
  background-image: linear-gradient(to right, #{$table-tiles-divider-color} 60%, transparent 0%);
  background-repeat: repeat-x;
  background-position: bottom;
  background-size: 8px $table-tiles-divider-height;
}

.table-tile-divider {
  padding-right: var(--table-tile-padding-x);
  border-right: 1px solid currentcolor;
}

// Table
.table-tiles {
  --table-tiles-column-gap: #{$table-tiles-column-gap};
  --table-tiles-row-gap: #{$table-tiles-row-gap};
  position: relative;
  width: 100%;
  padding-bottom: spacer(2); // This is to avoid cropping the shadow of the tiles
  border-spacing: var(--table-tiles-column-gap) var(--table-tiles-row-gap);
  border-collapse: separate;

  tr > *:last-child .table-tile-divider {
    padding-right: 0;
    border-right: 0;
  }
}

// Table tiles radio
.table-tiles-radio {
  --table-tiles-column-gap: 0;
  --table-tiles-row-gap: #{spacer(5)};
  padding-bottom: 0;

  .form-check-input {
    vertical-align: middle;
  }

  .table-tile {
    --table-tile-padding-y: #{spacer(4)};
    --table-tile-padding-x: #{spacer(4)};
    border: var(--#{$prefix}border-width) solid $gray-200;
    box-shadow: none;

    &:not(:nth-child(2)) {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child) {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
