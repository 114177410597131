/*
 * Content:
 *
 * Documents list
 * Previsualization container
 * React PDF page canvas
 */


// Documents list
// ============================================================================

.documents-list {
  @include list-unstyled();
}

.documents-list-item {
  display: flex;
  align-items: center;
  padding: $documents-list-item-padding-y $documents-list-item-padding-x;
  margin-bottom: spacer(4);
  font-weight: $font-weight-bold;
  color: $documents-list-item-color;
  background: $documents-list-item-bg;
  border-radius: $documents-list-item-border-radius;
  box-shadow: $documents-list-item-box-shadow;
  gap: spacer(4);

  &::before {
    display: inline-block;
    flex-shrink: 0;
    width: $documents-list-item-icon-width;
    height: $documents-list-item-icon-width;
    content: "";
    background-image: escape-svg($documents-list-item-icon-bg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .btn-close {
    flex-shrink: 0;
    width: $documents-list-btn-close-width;
    height: $documents-list-btn-close-width;
    padding: $documents-list-item-padding-y $documents-list-item-padding-x;
    background-image: escape-svg($documents-list-btn-close-bg);
    background-size: $documents-list-btn-close-width;
    border: none;
    margin-inline: auto ($documents-list-item-padding-x * -1);
  }
}


// Previsualization container
// ============================================================================
.previsualization-container {
  height: 650px;
  margin-bottom: spacer(6);
  overflow-y: auto;
}


// React PDF page canvas
// ============================================================================
/* stylelint-disable selector-class-pattern, declaration-no-important */
.react-pdf__Page__canvas {
  margin-inline: auto !important;
}
/* stylelint-enable selector-class-pattern, declaration-no-important */
