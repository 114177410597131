/*
 * Content:
 *
 * Modules
 * Input search
 * Form text
 * Form control
 * Form select
 * Checkbox
 * Switch
 * Input range
 * Textarea
 * Input type number
 * Dropzone
 * Error adornment
 * End adornment
 * Error list
 * Manual labels input hidding
 * Validation
 */

// Modules
// ============================================================================

@use "sass:map";


// Input search
// ============================================================================

input[type="search"] {
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    appearance: none;
  }
}


// Form text
// ============================================================================

.form-text {
  display: block;
  padding-inline: add($input-padding-x, $input-border-width);
}


// Form control
// ============================================================================

.form-control {
  &::placeholder {
    color: $input-placeholder-color;
  }

  &:hover:not(:disabled) {
    box-shadow: $input-hover-box-shadow;
  }

  &:focus {
    outline: $input-focus-outline;
  }

  &:disabled {
    &::placeholder {
      color: $input-disabled-color;
    }

    &:hover {
      box-shadow: none;
    }
  }
}

.form-control-sm {
  padding: spacer(3);
  font-size: $font-size-base;
  border-radius: spacer(2);
}

.form-control-end-adornment {
  padding-right: $form-adornment-padding-right;
}


// Form select
// ============================================================================

.form-select {
  &:hover:not(:disabled) {
    box-shadow: $form-select-hover-box-shadow;
  }

  &:focus {
    outline: $form-select-focus-outline;
  }
}


// Checkbox
// ============================================================================

.form-check,
.form-check-label {
  font-size: $form-check-label-font-size;
}

.form-check-label {
  font-weight: $form-label-font-weight;
  vertical-align: top;
}

.form-check-input {
  margin-top: 0;

  &:disabled {
    background-color: $light-disable;
  }

  &:checked {
    background-color: map.get($theme-colors, "secondary");
    border-color: map.get($theme-colors, "secondary");

    &:hover:not(:focus):not(:disabled) {
      background-color: map.get($theme-colors-hover, "secondary");
      border-color: map.get($theme-colors-hover, "secondary");
    }

    &:focus {
      background-color: map.get($theme-colors-focus, "secondary");
      border-color: map.get($theme-colors-focus, "secondary");
    }

    &:disabled {
      background-color: $light-disable;
      border-color: $light-disable;
    }
  }

  &[type="checkbox"]:indeterminate {
    &:hover:not(:focus):not(:disabled) {
      background-color: $form-check-input-bg;
      background-image: escape-svg(str-replace($form-check-input-indeterminate-bg-image, "#{$form-check-input-indeterminate-color}", "#{map.get($theme-colors-hover, " secondary")}"));
    }

    &:focus {
      background-color: $form-check-input-bg;
      background-image: escape-svg(str-replace($form-check-input-indeterminate-bg-image, "#{$form-check-input-indeterminate-color}", "#{map.get($theme-colors-focus, " secondary")}"));
    }

    &:disabled {
      background-color: $form-check-input-bg;
      background-image: escape-svg(str-replace($form-check-input-indeterminate-bg-image, "#{$form-check-input-indeterminate-color}", "#{$light}"));
      border-color: $light;
    }
  }
}


// Switch
// ============================================================================
.form-check.form-switch {
  .form-check-label {
    font-size: $form-check-switch-label-font-size;
  }

  .form-check-input {
    width: $form-check-input-switch-width;
    margin-top: ($form-check-switch-label-font-size * $line-height-base - $form-check-input-width) * .5;
  }
}


// Input range
// ============================================================================

.input-range {
  position: relative;
  width: 100%;
  cursor: pointer;
  background: $track-background;
  background-image: linear-gradient($secondary, $secondary);
  background-repeat: no-repeat;
  border-radius: $border-radius-xl;
  appearance: none;


  // Track
  &::-webkit-slider-runnable-track {
    height: spacer(2);
    background: transparent;
    border-radius: $border-radius-xl;
  }

  // Thumb
  &::-webkit-slider-thumb {
    width: spacer(6);
    height: spacer(6);
    margin-top: -12px;
    background-color: $secondary;
    border-radius: $border-radius-pill;
    appearance: none;
  }

  &::before {
    position: absolute;
    top: spacer(5);
    left: 0;
    font-size: $font-size-sm;
    color: $gray-700;
    content: attr(data-min-amount);
  }

  &::after {
    position: absolute;
    top: spacer(5);
    right: 0;
    font-size: $font-size-sm;
    color: $gray-700;
    content: attr(data-max-amount);
  }
}


// Textarea
// ============================================================================

textarea {
  resize: none;
}


// Input type number
// ============================================================================

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}


// Dropzone
// ============================================================================

.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: spacer(3);
  padding: spacer(6);
  color: $text-subtitle;
  text-align: center;
  border: 2px dotted $text-disabled;
  border-radius: $border-radius;
}

.dropzone-text {
  max-width: rem(270px);
}


// End adornment
// ============================================================================

.end-adornment {
  position: absolute;
  top: 50%;
  right: spacer(3);
  transform: translate(0, -50%);
}

// Errors List
// ============================================================================
.errorlist {
  margin: 0;
}


// Manual labels input hidding
// Targets form fields which wants to hide parent container div
// ============================================================================

div:has(> input[data-hide-container="true"].d-none) {
  display: none;
}
