/*
 * Content:
 *
 * Modules
 * Root
 * Year selector
 * Month selector
 * Day selector
 */

// Modules
// ============================================================================

@use "sass:map";


/* stylelint-disable selector-class-pattern, declaration-no-important */
// Root
// ============================================================================
.MuiTextField-root {
  width: 100%;

  .MuiInputBase-root {
    font-family: $input-font-family;
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    background-color: $input-bg;
    border-radius: $input-border-radius;
    transition: $input-transition;

    fieldset {
      transition: $input-transition;
    }

    &.MuiInputBase-adornedEnd {
      padding-right: $input-padding-x;
    }

    fieldset,
    &:hover fieldset {
      border-color: $input-border-color;
    }

    &:hover:not(.Mui-disabled) fieldset {
      box-shadow: $input-hover-box-shadow;
    }

    &.Mui-focused fieldset {
      border-color: $input-focus-border-color;
    }

    &.Mui-disabled {
      background-color: $input-disabled-bg;

      fieldset {
        border-color: $input-disabled-border-color;
      }

      .MuiInputBase-input {
        -webkit-text-fill-color: currentcolor;
        color: $input-disabled-color;
      }
    }
  }

  .MuiInputBase-input {
    height: unset;
    padding: add($input-padding-y, $input-border-width) 0 add($input-padding-y, $input-border-width) $input-padding-x;

    &::placeholder {
      color: $input-placeholder-color;
      opacity: 1;
    }
  }

  .MuiIconButton-edgeEnd {
    margin-inline: spacer(2) * -1;
  }

  @each $state, $data in $form-validation-states {
    $border-color: map.get($data, "border-color");
    $icon: map.get($data, "icon");

    &.is-#{$state} {
      .MuiInputBase-root fieldset {
        border-color: $border-color;
      }

      @if $enable-validation-icons {
        .MuiInputBase-input {
          padding-right: $input-padding-x + $input-feedback-icon-size;
          background-image: escape-svg($icon);
          background-repeat: no-repeat;
          background-position: right center;
          background-size: $input-feedback-icon-size;
        }
      }
    }
  }
}

// Year selector
// ============================================================================
.MuiPickersYear-yearButton.Mui-selected {
  background-color: $secondary !important;
}

// Month selector
// ============================================================================
.MuiPickersMonth-monthButton.Mui-selected {
  background-color: $secondary !important;
}

// Day selector
// ============================================================================
.MuiPickersDay-root {
  &.Mui-selected {
    background-color: $secondary !important;
  }

  &.MuiPickersDay-today {
    color: $secondary !important;
    border-color: $secondary !important;

    &:hover,
    &:focus {
      background-color: transparent !important;
    }
  }
}
/* stylelint-enable selector-class-pattern, declaration-no-important */
