/*
 * Content:
 *
 * Offcanvas header
 * Offcanvas title
 * Offcanvas separator
 */

// Offcanvas header
// ============================================================================

.offcanvas-header {
  padding-bottom: spacer(2);

  .btn-close {
    padding: $btn-close-padding-y $btn-close-padding-x;
    margin: 0 0 0 auto;
    border: none;
  }
}

// Offcanvas title
// ============================================================================

.offcanvas-title {
  margin-bottom: 0;
  font-size: $font-size-xl;
  font-weight: $font-weight-normal;
  color: $text-subtitle;
}

// Offcanvas separator
// ============================================================================

.offcanvas-separator {
  padding-inline: $offcanvas-padding-x;

  &::after {
    display: block;
    width: 100%;
    height: 1px;
    content: "";
    background-color: $gray-300;
  }
}
